import { defineStore } from "pinia";
import { ref } from "vue";
import { DefaultBaseResponse } from "@/constants/DefaultBaseResponse";
import { isDevelopment } from "@/helpers/environment";
import { http } from "@/helpers/lib/http";
import { BaseActionMessage, BroadcastService } from "@/services/shared/BroadcastService";
import { GetSsoSettingsResponse, IsAuthenticatedResponse, MfaMethod } from "@/types/_generated/api";
import { handleError } from "../services/shared/handleError";
import { useAppStore } from "./useAppStore";
import { useNotificationsStore } from "./useNotificationsStore";

export type AuthMessage = BaseActionMessage & {
  action: "login" | "logout";
};

export const remoteAuthBroadcastService = new BroadcastService<AuthMessage>("auth", "remote");

export const useAuthStore = defineStore("authStore", () => {
  const appStore = useAppStore();
  const notificationsStore = useNotificationsStore();

  const isAuthenticated = ref(false);
  const isImpersonated = ref(false);

  const signInCredentials = async (
    username: string,
    password: string,
    meta?: { mfaCode?: string; mfaMethod?: MfaMethod },
  ) => {
    await http.request({
      method: "POST",
      url: "/auth/credentials",
      data: { username, password, meta },
      baseURL: "",
    });
  };

  const getSSOSettings = async (): Promise<GetSsoSettingsResponse> => {
    try {
      const { data } = await http.get<GetSsoSettingsResponse>("/login/sso-settings");
      return data;
    } catch (error) {
      if (isDevelopment) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }

    return Object.assign(new DefaultBaseResponse(), { showSsoLogin: false });
  };

  const signInSSO = async (username: string, key: string): Promise<boolean> => {
    try {
      await http.request({
        method: "POST",
        url: "/auth/sso",
        data: { username, password: key },
        baseURL: "",
      });

      return true;
    } catch (error) {
      handleError(error);
      reset();
    }
    return false;
  };

  const signOut = async () => {
    http
      .request({
        method: "POST",
        url: "/auth/logout",
        baseURL: "",
      })
      .finally(() => {
        reset();
        remoteAuthBroadcastService.post({ action: "logout" });
      });
  };

  const hasSession = async () => {
    const { data } = await http.get<IsAuthenticatedResponse>("/login/is-authenticated");
    if (data.isAuthenticated && data.user) {
      isAuthenticated.value = true;

      await appStore.updateUser(data.user);

      isImpersonated.value = data.isImpersonated;
    } else {
      reset();
    }

    return data.isAuthenticated;
  };

  const acceptTermsOfService = async () => {
    try {
      await http.put("/login/accept-terms");
      await hasSession();
    } catch (error) {
      handleError(error);
    }
  };

  const $reset = () => {
    isAuthenticated.value = false;
    isImpersonated.value = false;
  };

  const reset = () => {
    appStore.resetStore();
    useAuthStore().$reset();
    notificationsStore.resetStore();
  };

  const handleSuccessfulLogin = async () => {
    await hasSession();
    isAuthenticated.value = true;
    remoteAuthBroadcastService.post({ action: "login" });
  };

  return {
    isImpersonated,
    isAuthenticated,
    acceptTermsOfService,
    getSSOSettings,
    handleSuccessfulLogin,
    hasSession,
    signInCredentials,
    signInSSO,
    signOut,
    $reset,
    reset,
  };
});
