import { http } from "@/helpers/lib/http";
import { t } from "@/helpers/lib/i18n";
import { GetApplicationSettingsResponse } from "@/types/_generated/api";
import { handleError } from "./shared/handleError";

export const getApplicationSettings = async (): Promise<GetApplicationSettingsResponse | null> => {
  try {
    const { data } = await http.get<GetApplicationSettingsResponse>(`/application-settings`);
    return data;
  } catch (error) {
    handleError(error, {
      action: t("common.actions.fetch").toLowerCase(),
      entity: t("common.settings"),
    });
  }
  return null;
};
