import { computed } from "vue";
import { useRouter } from "vue-router";
import { UserWebRouteName, AdminRouteName } from "@/router/RouteName";
import { useAuthStore } from "@/stores/auth";
import { useAppStore } from "@/stores/useAppStore";
import { Role } from "@/types/_generated/api";

export const useAuth = () => {
  const router = useRouter();
  const appStore = useAppStore();
  const authStore = useAuthStore();

  const isFullyAuthenticated = computed(
    () => authStore.isAuthenticated && appStore.user.value?.hasAcceptedTermsOfService,
  );

  const redirectOnLogin = () => {
    const goRoute = router.currentRoute.value.query?.go;

    if (goRoute) {
      router.push(decodeURIComponent(goRoute as string));
    } else if (appStore.user.value?.role === Role.EXT && appStore.user.value?.allowUserWeb) {
      router.push({ name: UserWebRouteName.Modules });
    } else {
      router.push({ name: AdminRouteName.AdminDashboard });
    }
  };

  return { isFullyAuthenticated, redirectOnLogin };
};
