type SupportedLocale = {
  icon: string;
  title: string;
  value: string;
};

export const supportedLocales: SupportedLocale[] = [
  { icon: "IconEnglish", title: "English", value: "en" },
  { icon: "IconSwedish", title: "Svenska", value: "sv" },
  { icon: "IconRussian", title: "русский", value: "ru" },
];
