import { computed, ref } from "vue";
import { supportedLocales } from "@/constants/SupportedLocales";
import { printSupportInfo } from "@/helpers/supportInfo";
// eslint-disable-next-line no-restricted-imports
import { setupI18n } from "@/i18n";
import { getApplicationSettings } from "@/services/applicationSettings";
import { type CurrentUserDto, Role } from "@/types/_generated/api";
import { ApplicationSettings } from "./types";

const APP_DEFAULT_SETTINGS: ApplicationSettings = {
  locale: "en",
  bingMapKey: undefined,
  defaultModuleRecommendationTypes: [],
  documentFileExtensions: [],
  documentMaxSizeInBytes: 0,
  excelFileExtensions: [],
  imageFileExtensions: [],
  imageMaxSizeInBytes: 0,
  recommendationResponseFileExtensions: [],
  version: "",
};

const appSettings = ref<ApplicationSettings>({ ...APP_DEFAULT_SETTINGS });
const user = ref<CurrentUserDto>();

export const useAppStore = () => {
  const isAdmin = computed(() => {
    const adminRoles = [Role.SystemOwner, Role.SA, Role.BA];
    return user.value ? adminRoles.includes(user.value.role) : false;
  });

  const currentLanguage = computed(
    () => supportedLocales.find((item) => item.value === appSettings.value.locale)?.title,
  );

  const updateLocale = (locale: string) => {
    setupI18n({ locale });
    appSettings.value.locale = locale;
  };

  const updateUser = async (currentUser: CurrentUserDto) => {
    try {
      if (!user.value && currentUser) {
        // Load settings
        const data = await getApplicationSettings();
        if (data) {
          appSettings.value = { ...appSettings.value, ...data };
        }
      }
      user.value = currentUser;
      updateLocale(currentUser.languageCode);
      printSupportInfo(appSettings.value, currentUser);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error("Failed to set user:", error);
    }
  };

  const resetStore = () => {
    appSettings.value = { ...APP_DEFAULT_SETTINGS };
    user.value = undefined;
  };

  return {
    appSettings,
    user,
    currentLanguage,
    isAdmin,
    updateLocale,
    updateUser,
    resetStore,
  };
};
