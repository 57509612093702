import type { RouteRecordRaw } from "vue-router";
import { authorize } from "@/plugins/can";
import { AdminRouteName } from "@/router/RouteName";
import { Role } from "@/types/_generated/api";

export const adminWebRoutes: Array<RouteRecordRaw> = [
  {
    path: "/admin",
    name: AdminRouteName.Root,
    redirect: { name: AdminRouteName.AdminDashboard },
    children: [
      {
        path: "dashboard",
        name: AdminRouteName.AdminDashboard,
        component: () => import("@/views/Admin/Dashboard/ViewDashboard.vue"),
      },
      {
        path: "users",
        name: AdminRouteName.Users,
        beforeEnter: (_to, _from, next) =>
          authorize.hasRole(Role.SystemOwner, Role.SA, Role.BA, Role.INT) && next(),
        component: () => import("@/views/Admin/Users/ViewUsers.vue"),
      },
      {
        path: "clients",
        name: AdminRouteName.Clients,
        component: () => import("@/views/Admin/Clients/ViewClients.vue"),
      },
      {
        path: "clients/:clientId",
        name: AdminRouteName.Client,
        beforeEnter: (_to, _from, next) => {
          if (authorize.hasModulePermissions()) {
            next();
          }
        },
        component: () => import("@/views/Admin/Clients/ViewClient.vue"),
        props: (route) => ({
          clientId: Number(route.params.clientId),
        }),
        redirect: { name: AdminRouteName.ClientModules },
        children: [
          {
            name: AdminRouteName.ClientModules,
            path: "modules",
            component: () => import("@/views/Admin/Modules/components/ModulesTable.vue"),
            props: (route) => ({
              clientId: Number(route.params.clientId),
              showClientName: false,
            }),
          },
          {
            name: AdminRouteName.ClientUsers,
            path: "users",
            component: () => import("@/views/Admin/Clients/components/ClientUsersTable.vue"),
            props: (route) => ({
              clientId: Number(route.params.clientId),
            }),
          },
          {
            name: AdminRouteName.ClientDetails,
            path: "client-details",
            component: () => import("@/views/Admin/Clients/components/ClientDetails.vue"),
            props: (route) => ({
              clientId: Number(route.params.clientId),
            }),
          },
        ],
      },
      {
        // Not named "modules" to avoid conflict with ServiceStack's virtual directories
        // https://stackoverflow.com/questions/75767096/servicestack-virtual-directories-collide-with-spa-routing
        path: "module-list",
        name: AdminRouteName.Modules,
        component: () => import("@/views/Admin/Modules/ViewModules.vue"),
      },
      {
        path: "modules/:moduleId",
        name: AdminRouteName.Module,
        props: (route) => ({
          moduleId: Number(route.params.moduleId),
        }),
        redirect: { name: AdminRouteName.ModuleSites },
        component: () => import("@/views/Admin/Modules/ViewModule.vue"),
        children: [
          {
            name: AdminRouteName.ModuleSites,
            path: "sites",
            component: () => import("@/views/Admin/Modules/components/ModuleSitesTable.vue"),
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
          },
          {
            name: AdminRouteName.ModuleUsers,
            path: "users",
            component: () => import("@/views/Admin/Modules/components/ModulePermissionsTable.vue"),
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
          },
          {
            name: AdminRouteName.ModuleDetails,
            path: "details",
            component: () => import("@/views/Admin/Modules/components/ModuleDetails.vue"),
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
          },
          {
            name: AdminRouteName.ModuleLibrary,
            path: "library",
            component: () => import("@/components/shared/ModuleLibrary/ModuleLibrary.vue"),
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
              isUserWeb: false,
            }),
          },
        ],
      },
      {
        path: "modules/:moduleId/questionnaire",
        name: AdminRouteName.Questionnaire,
        props: (route) => ({
          moduleId: Number(route.params.moduleId),
          questionnaireId: null,
        }),
        redirect: { name: AdminRouteName.QuestionnaireCurrent },
        component: () => import("@/views/Admin/Questionnaire/ViewQuestionnaire.vue"),
        children: [
          {
            path: "current",
            name: AdminRouteName.QuestionnaireCurrent,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
            component: () => import("@/views/Admin/Questionnaire/ViewQuestionnaireCurrent.vue"),
          },
          {
            path: "draft",
            name: AdminRouteName.QuestionnaireDraft,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
              questionnaireId: null,
            }),
            component: () => import("@/views/Admin/Questionnaire/ViewQuestionnaireDraft.vue"),
            beforeEnter: (to, _from, next) =>
              authorize.hasModulePermission(Number(to.params.moduleId), "editQuestionnaire") &&
              next(),
          },
        ],
      },
      {
        path: "modules/:moduleId/sites/:siteId",
        name: AdminRouteName.Site,
        props: (route) => ({
          moduleId: Number(route.params.moduleId),
          siteId: Number(route.params.siteId),
        }),
        redirect: { name: AdminRouteName.SiteSurveys },
        component: () => import("@/views/Admin/Sites/ViewSite.vue"),
        children: [
          {
            name: AdminRouteName.SiteSurveys,
            path: "surveys",
            component: () => import("@/views/Admin/Sites/components/SiteSurveysTable.vue"),
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
              siteId: Number(route.params.siteId),
            }),
          },
          {
            name: AdminRouteName.SitePermissionsTable,
            path: "permissions",
            component: () => import("@/views/Admin/Sites/components/SitePermissionsTable.vue"),
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
              siteId: Number(route.params.siteId),
            }),
          },
          {
            name: AdminRouteName.SiteExemptionsTable,
            path: "exemptions",
            component: () => import("@/views/Admin/Sites/components/SiteExemptionsTable.vue"),
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
              siteId: Number(route.params.siteId),
            }),
          },
          {
            name: AdminRouteName.SiteDetails,
            path: "details",
            component: () => import("@/views/Admin/Sites/components/SiteDetails.vue"),
            props: (route) => ({
              siteId: Number(route.params.siteId),
            }),
          },
        ],
      },
      {
        path: "modules/:moduleId/sites/:siteId/surveys/:surveyId",
        redirect: { name: AdminRouteName.BlueRatingSurvey },
        name: AdminRouteName.BlueRating,
        component: () => import("@/views/Admin/BlueRating/ViewBlueRating.vue"),
        props: ({ params }) => ({
          moduleId: Number(params.moduleId),
          siteId: Number(params.siteId),
          surveyId: Number(params.surveyId),
        }),
        children: [
          {
            name: AdminRouteName.BlueRatingSurvey,
            path: "survey",
            component: () => Promise.resolve({ render: () => null }),
          },
          {
            name: AdminRouteName.BlueRatingRecommendations,
            path: "recommendations",
            component: () => Promise.resolve({ render: () => null }),
          },
        ],
      },
      {
        name: AdminRouteName.Templates,
        path: "templates",
        redirect: { name: AdminRouteName.QuestionnaireTemplates },
        component: () => import("@/views/Admin/Templates/ViewTemplates.vue"),
        children: [
          {
            name: AdminRouteName.QuestionnaireTemplates,
            path: "questionnaire",
            component: () =>
              import("@/views/Admin/Templates/components/QuestionnaireTemplatesTable.vue"),
            beforeEnter: (_to, _from, next) => {
              if (authorize.isSuperiorOrEqualTo(Role.BA)) {
                next();
              } else {
                next({ name: AdminRouteName.StandardRecommendations });
              }
            },
          },
          {
            name: AdminRouteName.StandardRecommendations,
            path: "standard-recommendations",
            props: (route) => ({
              standardRecommendationId: Number(route.query.standardRecommendationId),
            }),
            component: () => import("@/views/Admin/Templates/components/StandardRecTable.vue"),
          },
        ],
      },
      {
        name: AdminRouteName.QuestionnaireTemplate,
        path: "templates/questionnaire/:questionnaireId",
        component: () => import("@/views/Admin/Questionnaire/ViewQuestionnaire.vue"),
        redirect: { name: AdminRouteName.QuestionnaireTemplateCurrent },
        props: (route) => ({
          questionnaireId: Number(route.params.questionnaireId),
          moduleId: null,
        }),
        children: [
          {
            path: "current",
            name: AdminRouteName.QuestionnaireTemplateCurrent,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
            }),
            component: () => import("@/views/Admin/Questionnaire/ViewQuestionnaireCurrent.vue"),
          },
          {
            path: "draft",
            name: AdminRouteName.QuestionnaireTemplateDraft,
            props: (route) => ({
              moduleId: Number(route.params.moduleId),
              questionnaireId: null,
            }),
            component: () => import("@/views/Admin/Questionnaire/ViewQuestionnaireDraft.vue"),
          },
        ],
      },
      {
        name: AdminRouteName.SystemAdmin,
        path: "/system-admin",
        component: () => import("@/views/Admin/System/ViewSystemAdmin.vue"),
        redirect: { name: AdminRouteName.LoginAttemptLog },
        children: [
          {
            path: "logs/login-attempts",
            name: AdminRouteName.LoginAttemptLog,
            component: () => import("@/views/Admin/System/components/SystemLoginAttemptLog.vue"),
          },
          {
            path: "logs/errors",
            name: AdminRouteName.ErrorLog,
            component: () => import("@/views/Admin/System/components/SystemErrorLog.vue"),
          },
          {
            path: "logs/activities",
            name: AdminRouteName.ActivityLog,
            component: () => import("@/views/Admin/System/components/SystemActivityLog.vue"),
          },
          {
            path: "service-messages",
            name: AdminRouteName.ServiceMessages,
            component: () => import("@/views/Admin/System/components/SystemServiceMessages.vue"),
          },
        ],
      },
    ],
  },
];
