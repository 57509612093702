<script setup lang="ts">
import { useDialogController } from "@/composables/useDialog";
import BaseModal from "@/components/base/BaseModal.vue";

const {
  isVisible,
  isDestructive,
  title,
  description,
  confirmText,
  cancelText,
  hideCancelButton,
  confirm,
  cancel,
} = useDialogController();
</script>

<template>
  <Transition name="fade" appear>
    <BaseModal
      v-if="isVisible"
      :title="title"
      :confirm-text="confirmText"
      :cancel-text="cancelText"
      :hide-cancel="hideCancelButton"
      :confirm-color="isDestructive ? 'error' : undefined"
      @confirm="confirm()"
      @cancel="cancel()"
    >
      <p>{{ description }}</p>
    </BaseModal>
  </Transition>
</template>
