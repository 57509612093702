<script setup lang="ts">
import { computed } from "vue";
import { t } from "@/helpers/lib/i18n";
import { useAppStore } from "@/stores/useAppStore";
import { useImpersonation } from "@/views/Admin/Users/composables/useImpersonation";
import BaseButton from "../base/BaseButton.vue";

const { user } = useAppStore();
const { endImpersonation, isLoadingImpersonation } = useImpersonation();

const fullName = computed(() => `${user.value?.firstName} ${user.value?.lastName}`);
</script>

<template>
  <aside class="app-impersonate-warning" role="alert" aria-live="polite">
    <BaseButton
      size="small"
      color="light"
      variant="text"
      :disabled="isLoadingImpersonation"
      @click="endImpersonation"
    >
      {{ t("impersonation.endImpersonation", { fullName }) }}
    </BaseButton>
  </aside>
</template>

<style scoped lang="scss">
.app-impersonate-warning {
  display: flex;
  align-items: center;
  justify-content: center;
  height: $impersonation-height;
  background-color: $error-4;
}
</style>
