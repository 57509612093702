<script setup lang="ts">
import { onMounted, watch } from "vue";
// eslint-disable-next-line no-restricted-imports
import { useI18n } from "vue-i18n";
import { useAuth } from "@/composables/useAuth";
import { useSessionTimeout } from "@/composables/useSessionTimeout";
import { useUnsavedChanges } from "@/composables/useUnsavedChanges";
import { addCookieBanner } from "@/cookie-banner";
import { remoteAuthBroadcastService, useAuthStore } from "@/stores/auth";
import { useAppStore } from "@/stores/useAppStore";
import { useNotificationsStore } from "@/stores/useNotificationsStore";
import AppDialog from "@/components/app/AppDialog.vue";
import AppImpersonateWarning from "@/components/app/AppImpersonateWarning.vue";
import AppSessionModal from "@/components/app/AppSessionModal.vue";
import AppToast from "@/components/app/AppToast.vue";
import AppUnsavedChangesModal from "@/components/app/AppUnsavedChangesModal.vue";

const i18n = useI18n();
const appStore = useAppStore();
const authStore = useAuthStore();
const notificationsStore = useNotificationsStore();
const { isFullyAuthenticated } = useAuth();
const {
  unsavedChangesDialog: { showDialog, ok, cancel },
} = useUnsavedChanges();
const { showSessionModal, timer, handleSignOut, closeModal } = useSessionTimeout();

remoteAuthBroadcastService.subscribe(() => window.document.location.reload(), ["login"]);
remoteAuthBroadcastService.subscribe(() => {
  authStore.reset();
  window.document.location.reload();
}, ["logout"]);

watch(
  // re-render component when language changes
  () => appStore.appSettings.value.locale,
  () => (i18n.locale.value = appStore.appSettings.value.locale),
);

onMounted(() => addCookieBanner());
</script>

<template>
  <AppImpersonateWarning v-if="authStore.isImpersonated" />

  <RouterView />

  <AppSessionModal
    v-if="showSessionModal && isFullyAuthenticated"
    :timer="timer"
    @close="closeModal"
    @sign-out="handleSignOut"
  />

  <AppToast :toasts="notificationsStore.toasts.value" @remove="notificationsStore.removeToast" />

  <AppDialog />

  <AppUnsavedChangesModal v-if="showDialog" @cancel="cancel" @confirm="ok" />
</template>
