import { isDevelopment } from "@/helpers/environment";

export type BaseActionMessage = {
  action: string;
};

export type BaseCRUDMessage = BaseActionMessage & {
  action: "create" | "update" | "delete";
};

export type BroadcastMessage = BaseCRUDMessage & {
  id: number | string | undefined;
};

type Notify = "local" | "remote" | "all";

/**
 * BroadcastService allows messages to be broadcasted
 * browser-wide (tabs, windows, iframes) within the domain. Two or more
 * BroadcastServices with the same name will be connected to the same channel.
 */
export class BroadcastService<T extends BaseActionMessage = BroadcastMessage> {
  private name: string;
  private channel: BroadcastChannel;
  private notify: Notify;
  private localListenerMap: Map<
    symbol,
    {
      fn: (message: T) => void;
      counter: number;
      once: boolean;
      filter: T["action"][];
    }
  >;

  constructor(name: string, notifyOnly: Notify = "all") {
    this.name = `blue-${name}`;
    this.localListenerMap = new Map();
    this.notify = notifyOnly;

    this.channel = new BroadcastChannel(this.name);
    this.channel.addEventListener("message", (event) => this.notifyLocalListeners(event));
    this.channel.addEventListener("messageerror", (event) => {
      if (isDevelopment) {
        // eslint-disable-next-line no-console
        console.error(`BroadcastService '${this.name}': message error`, event);
      }
    });
  }

  /**
   * Call all local listeners with the message in the order of subscription.
   * @param event Message event either from channel or local post.
   */
  private notifyLocalListeners(event: MessageEvent<T>): void {
    // Slice keys to avoid mutation during iteration
    const keys = [...this.localListenerMap.keys()];

    for (const key of keys) {
      const listener = this.localListenerMap.get(key);
      if (!listener) continue;

      if (listener.filter.length && !listener.filter.includes(event.data.action)) continue;

      try {
        listener.fn(event.data);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        listener.counter += 1;
        if (listener.once) this.unsubscribe(key);
      }
    }
  }

  /**
   * Post message to local listeners and other tabs / windows
   * listening to the same channel.
   * @param message
   */
  post(message: T): void {
    // Post message to channel (other tabs / windows)
    if (["remote", "all"].includes(this.notify)) {
      this.channel.postMessage(message);
    }

    // Post message to local listeners
    if (["local", "all"].includes(this.notify)) {
      this.notifyLocalListeners({ data: message } as MessageEvent<T>);
    }
  }

  /**
   * Subscribe to browser-wide messages.
   * @param callback The function that will be called when a message is received.
   * @param filter Only call callback when message.action is in filter.
   * @param once Unsubscribe when called once.
   * @returns Symbol that can be used to unsubscribe.
   */
  subscribe(
    callback: (message: T) => void,
    filter: T["action"][] | null = null,
    once = false,
  ): symbol {
    // Finally a usecase for symbols
    const symbol = Symbol();

    this.localListenerMap.set(symbol, {
      counter: 0,
      fn: callback,
      once,
      filter: filter || [],
    });

    return symbol;
  }

  /**
   * Unsubscribe from service.
   * @param symbol The symbol returned from subscribe.
   */
  unsubscribe(symbol: symbol | undefined): void {
    // Allow undefined for symbol to ease using with refs
    if (!symbol) return;

    const listener = this.localListenerMap.get(symbol);
    if (!listener) return;

    this.localListenerMap.delete(symbol);
  }
}
